import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ResponsiveImage from "./Img/ResponsiveImage";
import useWindowSize from "../utils/useWindowSize";

const Cocktails = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  const windowSize = useWindowSize();

  const isMobile = windowSize.width <= 991;
  const imageSrc = isMobile
    ? "img/menu/cocktails-menu-mobile.webp"
    : "img/menu/cocktails-menu.jpg";
  return (
    <CocktailsDiv ref={ref}>
      <ResponsiveImage
        src={imageSrc}
        alt="cocktails-background"
        fluidImage={true}
        customStyles={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      />
      <div>
        <h2 title={t("cocktails-h-o")}>{t("cocktails-h-o")}</h2>
        <p>
          <span>{t("cocktails-p-o")}</span> {t("cocktails-p-t")}
        </p>
        <a
          href={`/pdf/BABEL_COCKTAIL_${i18n.language.toUpperCase()}.pdf`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Cocktails
        </a>
      </div>
    </CocktailsDiv>
  );
});

// const CocktailsDiv = styled.div`
//   width: 100vw;
//   height: 100vh;
//   position: relative;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   overflow: hidden;

//   & > div {
//     ::-webkit-scrollbar {
//       width: 8px;
//     }
//     ::-webkit-scrollbar-track {
//       border-radius: 8px;
//       background-color: rgba(255, 255, 255, 0.2);
//     }
//     ::-webkit-scrollbar-thumb {
//       background: rgba(255, 255, 255, 0.8);
//       border-radius: 10px;
//     }
//     ::-webkit-scrollbar-thumb:hover {
//       background: rgba(255, 255, 255, 1);
//     }
//     height: auto;
//     width: 100%;
//     max-width: 600px;
//     position: absolute;
//     z-index: 1;
//     display: flex;
//     flex-flow: column nowrap;
//     justify-content: center;
//     padding: 20px 40px;
//     margin-right: 40px;

//     & > h2 {
//       height: auto;
//       width: 100%;
//       font-size: 40px;
//       line-height: 60px;
//       text-transform: uppercase;
//       font-weight: 400;
//     }

//     & > p {
//       font-size: 16px;
//       line-height: 24px;
//       width: 100%;

//       & > span {
//         font-weight: 700;
//       }
//     }

//     & > a {
//       border: 1px solid var(--babel-grey);
//       font-size: 16px;
//       padding: 10px;
//       width: 100%;
//       max-width: 250px;
//       text-align: center;
//       cursor: pointer;
//       transition: width 1.2s;
//       font-weight: 300;
//       background: var(--babel-turquoise);
//       color: var(--black);

//       &:hover {
//         background: var(--title-color);
//         border: 1px solid var(--title-color);
//       }
//     }
//   }

//   @media (max-width: 991px) {
//     height: auto;
//     min-height: 100vh;
//     padding: 0;
//     background-size: contain;
//     background-position: top;
//     flex-direction: column;
//     justify-content: center;
//     align-items: flex-end;

//     img {
//       position: relative;
//       object-fit: contain;
//     }

//     & > div {
//       max-height: unset;
//       position: unset;
//       justify-content: flex-end;
//       padding: 20px;
//       margin-right: 0px;
//       text-align: center;

//       & > h2 {
//         font-size: 36px;
//         line-height: 40px;
//         margin: 0 0 24px 0;
//       }

//       & > p {
//         max-width: unset;
//         width: 100%;
//         margin-right: 0;
//         font-size: 16px;
//         margin-bottom: 12px;
//       }

//       & > a {
//         margin: 0 auto;
//       }
//     }
//   }
// `;
const CocktailsDiv = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > div:nth-child(2) {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.2);
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 1);
    }
    height: auto;
    width: 100%;
    max-width: 600px;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 20px 40px;
    margin-right: 40px;

    & > h2 {
      height: auto;
      width: 100%;
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
    }

    & > p {
      font-size: 16px;
      line-height: 24px;
      width: 100%;

      & > span {
        font-weight: 700;
      }
    }

    & > a {
      border: 1px solid var(--babel-grey);
      font-size: 16px;
      padding: 10px;
      width: 100%;
      max-width: 250px;
      text-align: center;
      cursor: pointer;
      transition: width 1.2s;
      font-weight: 300;
      background: var(--babel-turquoise);
      color: var(--black);

      &:hover {
        background: var(--title-color);
        border: 1px solid var(--title-color);
      }
    }
  }

  @media (max-width: 991px) {
    height: auto;
    min-height: 100vh;
    padding: 0;
    background-size: contain;
    background-position: top;
    flex-direction: column;
    top: 0;
    justify-content: center;
    align-items: flex-end;
    img {
      position: relative;
      object-fit: contain;
      top: -10px;
    }
    & > div:nth-child(2) {
      max-height: unset;
      position: unset;
      justify-content: flex-end;
      padding: 20px;
      margin-right: 0px;
      text-align: center;

      & > h2 {
        font-size: 36px;
        line-height: 40px;
        margin: 0 0 24px 0;
      }

      & > p {
        max-width: unset;
        width: 100%;
        margin-right: 0;
        font-size: 16px;
        margin-bottom: 12px;
      }

      & > a {
        margin: 0 auto;
      }
    }
  }
`;
export default Cocktails;
